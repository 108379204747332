import * as React from "react";
const SVGsquarenormal = (props) => (


    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 420 420"
        xmlSpace="preserve"
        {...props}
    >
        <radialGradient
            id="a"
            cx={210}
            cy={210}
            r={210}
            gradientTransform="matrix(1 0 0 -1 0 420)"
            gradientUnits="userSpaceOnUse"
        >
            <stop
                offset={0.19}
                style={{
                    stopColor: props.bg1,
                }}
            />
            <stop
                offset={0.688}
                style={{
                    stopColor: props.bg2,
                }}
            />
            <stop
                offset={0.971}
                style={{
                    stopColor: props.bg3,
                }}
            />
        </radialGradient>
        <path fill="url(#a)" d="M0 0h420v420H0V0z" />
        <path
            fill="#666"
            d="M132 138.5V99.2c0-43 35-78 78-78s78 35 78 78v39.3h-.6V99.2c0-42.7-34.7-77.4-77.4-77.4s-77.4 34.7-77.4 77.4v39.3h-.6z"
        />
        <path
            fill="#333"
            d="M136.5 138.5s-.1-38.7-.1-39.3c0-40.5 33-73.5 73.5-73.5 40.9 0 73.6 33 73.6 73.5 0 .6-.1 39.3-.1 39.3h-3s.1-38.7.1-39.3c0-38.9-31.7-70.7-70.7-70.7-38.9 0-70.7 31.7-70.7 70.7 0 .6.1 39.3.1 39.3h-2.7z"
        />
        <path
            fill="#737373"
            d="M146.7 138.5V99.2c0-34.9 28.3-63.2 63.2-63.2s63.2 28.3 63.2 63.2c0 .6-.1 39.3-.1 39.3h-1.5s.1-38.7.1-39.3c0-34-27.7-61.7-61.7-61.7-33.7 0-61.6 27.7-61.6 61.7 0 .6.1 39.3.1 39.3h-1.7z"
        />
        <path
            fill="#F2F2F2"
            d="M141.2 138.5s-.1-38.7-.1-39.3c0-38 30.9-68.9 68.9-68.9s68.9 30.9 68.9 68.9c0 .6-.1 39.3-.1 39.3h-1.7s.1-38.7.1-39.3c0-37-30.1-67.2-67.2-67.2-37 0-67.2 30.1-67.2 67.2 0 .6.1 39.3.1 39.3h-1.7z"
        />
        <path
            fill="#BCBCBC"
            d="M132.6 138.5V99.2c0-42.7 34.7-77.4 77.4-77.4s77.4 34.7 77.4 77.4v39.3h-3.9s.1-38.7.1-39.3c0-40.5-33-73.5-73.5-73.5s-73.5 33-73.5 73.5c0 .6.1 39.3.1 39.3h-4.1z"
        />
        <path
            fill="#CCC"
            d="M150.9 138.5s-.1-38.7-.1-39.3c0-32.7 26.6-59.3 59.2-59.3s59.2 26.6 59.2 59.3c0 .6-.1 39.3-.1 39.3h-1.7s.1-38.7.1-39.3c0-31.7-25.8-57.5-57.5-57.5s-57.5 25.8-57.5 57.5c0 .6.1 39.3.1 39.3h-1.7z"
        />
        <path
            fill="#999"
            d="M154.3 138.5s-.1-38.7-.1-39.3c0-30.7 25.1-55.8 55.8-55.8s55.8 25.1 55.8 55.8c0 .6-.1 39.3-.1 39.3h-1.1V99.2c0-30.2-24.5-54.7-54.7-54.7S155.2 69 155.2 99.2v39.3h-.9z"
        />
        <path
            fill="#D0D0D0"
            d="M141.2 138.5s-.1-38.7-.1-39.3c0-38 30.9-68.9 68.9-68.9s68.9 30.9 68.9 68.9c0 .6-.1 39.3-.1 39.3h1.9s.1-38.7.1-39.3c0-38.9-31.7-70.7-70.7-70.7-38.9 0-71 31.7-71 70.7 0 .6.1 39.3.1 39.3h2z"
        />
        <path
            fill="#7F7F7F"
            d="M145.1 138.5s-.1-38.7-.1-39.3c0-35.9 29.1-65 65-65s65 29.1 65 65c0 .6-.1 39.3-.1 39.3H273s.1-38.7.1-39.3c0-34.9-28.5-63.2-63-63.2-34.9 0-63.2 28.3-63.2 63.2v39.3h-1.8z"
        />
        <path
            fill="#E2E2E2"
            d="m142.9 138.5-.1-39.3c0-37 30.1-67.2 67.2-67.2 37 0 67.2 30.1 67.2 67.2 0 .6-.1 39.3-.1 39.3h-2.2s.1-38.7.1-39.3c0-35.9-29.1-65-65-65s-65 29.1-65 65l.1 39.3h-2.2z"
        />
        <path
            fill="#ADADAD"
            d="M152.6 138.5s-.1-38.7-.1-39.3c0-31.7 25.8-57.5 57.5-57.5s57.5 25.8 57.5 57.5c0 .6-.1 39.3-.1 39.3h-1.8s.1-38.7.1-39.3c0-30.7-25.1-55.8-55.8-55.8s-55.8 25.1-55.8 55.8c0 .6.1 39.3.1 39.3h-1.6z"
        />
        <path
            fill="#848484"
            d="M150 138.5s-.1-38.7-.1-39.3c0-33.1 27-60.1 60.1-60.1s60.1 27 60.1 60.1c0 .6-.1 39.3-.1 39.3h1.6s.1-38.7.1-39.3c0-34-27.7-61.7-61.7-61.7s-61.7 27.7-61.7 61.7c0 .6.1 39.3.1 39.3h1.6z"
        />
        <path
            fill="#B5B5B5"
            d="M150 138.5s-.1-38.7-.1-39.4c0-33.1 27-60.1 60.1-60.1s60.1 27 60.1 60.1c0 .6-.1 39.4-.1 39.4h-.9s.1-38.7.1-39.4c0-32.7-26.6-59.2-59.2-59.2s-59.2 26.6-59.2 59.2c0 .6.1 39.4.1 39.4h-.9z"
        />
        <path
            fill={props.lock_color}
            d="M298.4 138.5H120.9c-1.6 0-2.9 1.3-2.9 2.9v159.8c0 1.6 1.3 2.9 2.9 2.9h177.5c1.6 0 2.9-1.3 2.9-2.9V141.4c0-1.6-1.3-2.9-2.9-2.9z"
        />
        <linearGradient
            id="b"
            gradientUnits="userSpaceOnUse"
            x1={118}
            y1={221.3}
            x2={301.3}
            y2={221.3}
        >
            <stop
                offset={0}
                style={{
                    stopColor: "#9c9c9c",
                    stopOpacity: 0.03,
                }}
            />
            <stop
                offset={0.06}
                style={{
                    stopColor: "#fff",
                    stopOpacity: 0.7,
                }}
            />
            <stop
                offset={0.1}
                style={{
                    stopColor: "#fff",
                    stopOpacity: 0,
                }}
            />
            <stop
                offset={0.484}
                style={{
                    stopColor: "#fff",
                    stopOpacity: 0.05,
                }}
            />
            <stop
                offset={0.9}
                style={{
                    stopColor: "#fff",
                    stopOpacity: 0,
                }}
            />
            <stop
                offset={0.94}
                style={{
                    stopColor: "#fff",
                    stopOpacity: 0.7,
                }}
            />
            <stop
                offset={1}
                style={{
                    stopColor: "#000",
                    stopOpacity: 0.03,
                }}
            />
        </linearGradient>
        <path
            fill="url(#b)"
            d="M298.4 138.5H120.9c-1.6 0-2.9 1.3-2.9 2.9v159.8c0 1.6 1.3 2.9 2.9 2.9h177.5c1.6 0 2.9-1.3 2.9-2.9V141.4c0-1.6-1.3-2.9-2.9-2.9z"
        />
        <path
            opacity={0.4}
            d="M118 300.8v.4c0 1.6 1.3 2.9 2.9 2.9h177.5c1.6 0 2.9-1.3 2.9-2.9v-.4H118z"
        />
        <path
            opacity={0.6}
            fill="#FFF"
            d="M298.4 138.5H120.9c-1.5 0-2.7 1.1-2.9 2.6h183.3c-.2-1.4-1.4-2.6-2.9-2.6z"
        />
        <path d="M210 252c-3.1 0-6.1-1.3-8.1-3.6s-3-5.4-2.6-8.4l1.8-16.2c.1-.8-.2-1.6-.8-2.2-2.6-2.6-4.1-6.2-4.1-9.8 0-7.6 6.2-13.8 13.8-13.8h.4c3.5.1 6.9 1.5 9.4 4s4 5.9 4 9.4c.1 3.8-1.4 7.6-4.1 10.2-.6.5-.9 1.3-.8 2.2l1.9 16.2c.4 3.1-.6 6.2-2.6 8.4-2.2 2.3-5.2 3.6-8.2 3.6z" />
        <path
            fill="#CCC"
            d="M209.9 251.6c-3 0-5.8-1.2-7.8-3.5-2-2.2-2.9-5.2-2.5-8.2l1.8-16.2c.1-.9-.2-1.8-.9-2.5-2.6-2.5-4-6-4-9.6 0-7.4 6.1-13.4 13.4-13.4h.4c3.4.1 6.7 1.5 9.1 4 2.5 2.5 3.9 5.7 4 9.1.1 3.7-1.4 7.4-4 9.9-.7.6-1 1.5-.9 2.5l1.9 16.2c.4 3-.6 6-2.5 8.2-2.2 2.2-5.1 3.5-8 3.5z"
        />
        <path d="M218.5 220.4c2.3-2.3 3.7-5.5 3.6-9-.2-6.4-5.4-11.6-11.8-11.8-6.8-.2-12.4 5.3-12.4 12 0 3.3 1.4 6.4 3.6 8.6 1 1 1.5 2.3 1.3 3.6L201 240c-.6 5.4 3.6 10.1 9.1 10.1s9.7-4.7 9.1-10.1l-1.9-16.2c-.2-1.2.2-2.6 1.2-3.4z" />

        <text
            x="50%"
            y="87%"
            dominantBaseline="middle"
            textAnchor="middle"
            style={{
                fontWeight: 900,
                fontFamily: "monospace",
                fill: props.text_color,
                fontSize: 24,
            }}
        >
            {props.name}
        </text>
        <text
            x="50%"
            y="94%"
            dominantBaseline="middle"
            textAnchor="middle"
            style={{
                fontWeight: 700,
                fontFamily: "monospace",
                fill: props.date_color,
                fontSize: 12,
                stroke: "none",
            }}
        >
            {props.date}
        </text>

    </svg>


);
export default SVGsquarenormal;
